

/**
 * 
 * @param {*} promise 对象
 * 统一处理 async..await 抓取报错
 * @returns 
 */
export function awaitWraper (promise) {
  return promise.then((res) => [null, res])
    .catch((err) => [err, null])
}