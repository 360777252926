import { Button, List, Cell, CellGroup, Tab, Tabs, NavBar, Icon, Rate, Popover, Popup, Cascader, BackTop } from 'vant'
export function vant (app) {
  app.use(Button);
  app.use(List)
  app.use(Cell);
  app.use(CellGroup);
  app.use(Tab);
  app.use(Tabs);
  app.use(NavBar);
  app.use(Icon);
  app.use(Rate);
  app.use(Popover);
  app.use(Popup);
  app.use(Cascader);
  app.use(BackTop);
}