import storage from '@/utils/storage'
const state = () => ({
  isIndoor: 0, //显示室内模式的标识
  subjectId: '', //主体id
  regionId: '',//当前选中的区域id
  streetSubjectId: null,//某个街道的subjectid
  levelOneName: '', //1级名称
  levelTwoName: '', //2级名称
  levelThreeName: '', //3级名称
  map: '', //地图实例
  isShowOutdoor: null, //显示室外的标识
  dragXValue: '',//拖拽X坐标的值
  dragYValue: '',//拖拽y坐标的值
  rierObj: {},//层级关系
  editorEchObj: {},//编辑回显内容
  pathArr: [],//标题
  threeSmallInfo: {},//点击时保存每个三小、物业、重点的信息
  communitySubjectId: null,//某社区的主体id
  isClickcommunity: false,//是否点击了社区
  isClickIndoor: false,//是否点击了室内
  isClickOutdoor: false,//是否点击了室外
  streetInfo: {},//每一个对应街道的信息
  outdoorSubjectId: null,//保存室外的subjectId
  currentSelectLevel: null,//保存当前从社区选中的层级
  cesiumMapRegionId: null,//保存cesium地图某块regionId
  subjectIdList: [],//室内返回时，保存之前的subjectId
  cameraHeight: null,//摄像机高度
  devicefalt: false,//设备管理-设备列表-查看设备进入大屏室外的标识
  baseSubjectVo: [],//自检自查
})

const mutations = {
  SET_INDOOR (state, param) {
    state.isIndoor = param
  },
  SET_SUBJECTID (state, val) {
    state.subjectId = val
  },
  SET_LEVELONENAME (state, val) {
    state.levelOneName = val
  },
  SET_LEVELTWONAME (state, val) {
    state.levelTwoName = val
  },
  SET_LEVELTHREENAME (state, val) {
    state.levelThreeName = val
  },
  SET_MAP (state, val) {
    state.map = val
  },
  SET_OUTDOOR (state, val) {
    state.isShowOutdoor = val
  },
  SET_STREETSUBJECTID (state, val) {
    state.streetSubjectId = val
  },
  SET_DRAGDROPXVAL (state, val) {
    state.dragXValue = val
  },
  SET_DRAGDROPYVAL (state, val) {
    state.dragYValue = val
  },
  SET_REGIONID (state, val) {
    state.regionId = val
  },
  SET_RIER (state, val) {//记录层级
    state.rierObj = val
  },
  SET_EDITORECHOBJ (state, val) {
    state.editorEchObj = val
  },
  SET_PATHARR (state, val) {
    state.pathArr.push(val)
  },
  SET_THREESMALLINFO (state, val) {
    state.threeSmallInfo = val
  },
  SET_COMMUNITYSUBJECTID (state, val) {
    state.communitySubjectId = val
  },
  SET_ISCLICKCOMMUNITY (state, val) {
    state.isClickcommunity = val
  },
  SET_ISCLICKINDOOR (state, val) {
    state.isClickIndoor = val
  },
  SET_ISCLICKOUTDOOR (state, val) {
    state.isClickOutdoor = val
  },
  SET_STREETINFO (state, val) {
    state.streetInfo = val
  },
  SET_OUTDOORSUBJECTID (state, val) {
    state.outdoorSubjectId = val
  },
  SET_CURRSEL (state, val) {
    state.currentSelectLevel = val
  },
  SET_CESIUMAPREGIONID (state, val) {
    state.cesiumMapRegionId = val
  },
  SET_SUBJECTIDLIST (state, val) {
    state.subjectIdList = val
  },
  SET_CAMERAHEIGHT (state, val) {
    state.cameraHeight = val
  },
  SET_DEVICEFALT (state, val) {
    state.devicefalt = val
  },
  SET_BASESUBJECTVO (state, val) {
    state.baseSubjectVo = [];
    state.baseSubjectVo.push(val)
    console.log('自检自查row', state.baseSubjectVo)
  }
}

const actions = {}

export default {
  namespaced: true,
  state: state,
  actions: actions,
  mutations: mutations,
}