const state = () => ({
  alarmType: '', //告警模块记录类型
})

const mutations = {
  SET_ALARMTYPE (state, param) {
    state.alarmType = param
  }
}

const actions = {

}

export default {
  namespaced: true,
  state: state,
  actions: actions,
  mutations: mutations,
}