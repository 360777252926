import Cookies from 'js-cookie'
import { useRoute } from 'vue-router'
const TokenKey = 'Authorization'

export function getToken() {
    let urlt = GetQueryString('t')
    if (urlt !== null && urlt.toString().length > 1) {
        return urlt
    }
    return Cookies.get(TokenKey)
}

export function setToken(token) {
    return Cookies.set(TokenKey, token)
}

export function removeToken() {
    return Cookies.remove(TokenKey)
}

export function checkToken() {
    if (Cookies.get(TokenKey)) {
        return true;
    } else {
        return false;
    }
}

export function hasToken() {
    const route = useRoute()
    let paramsT = route.query.t
    let sId = route.query.sId
    if (paramsT !== undefined) {
        setToken(paramsT)
        console.log('设置了token', paramsT)
    }
    return { t: paramsT, s: sId }
}

function GetQueryString(name) {
    var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
    var r = window.location.search.substr(1).match(reg); //search,查询？后面的参数，并匹配正则
    if (r != null) return unescape(r[2]);
    return null;
}