import { getUserInfo } from "@/api/user";
import { getlogin } from "@/api/login";
import { getToken, setToken, removeToken } from "@/utils/auth";
import { initWebsocket } from "@/utils/webSocket";
import { GlobalWs } from "@/utils/globalWs";
import storage from "@/utils/storage";
import { Encrypt, md5Encrypt } from "@/utils/Aes.js";
const state = () => ({
  token: null || getToken(), //token
  userInfo: storage.get("userInfo") || {}, //用户信息
  headerInfo: {}, //主体信息
  hasFlat: null,
});
const mutations = {
  SET_TOKEN (state, token) {
    setToken(token);
    state.token = token;
  },
  SET_USERINFO (state, userInfo) {
    storage.set("userInfo", userInfo); //将信息存储到本地
    state.userInfo = userInfo;
  },
  CLEAR_USER (state) {
    state.userInfo = {};
    storage.remove("userInfo");
    storage.remove("subjectVo");
    storage.remove("menuGroup");
  },
  CLEAR_TOKEN (state) {
    state.token = null;
    removeToken();
  },
  SET_HEADNAME (state, val) {
    storage.set("subjectVo", val); //将信息存储到本地
    state.headerInfo = val;
  },
  SET_HASFLAT (state, val) {
    state.hasFlat = val;
  },
};

const actions = {
  async login ({ commit }, form) {
    console.log("form", form);
    let { userPassword } = form;
    userPassword = md5Encrypt(userPassword);
    const params = { ...form, userPassword };

    return getlogin(params).then((res) => {
      commit("SET_TOKEN", res.data.token); //存储token vueX中
      commit("SET_USERINFO", res.data.baseUserVo); //存储用户信息vueX中
      commit("SET_HEADNAME", res.data.subjectVo);
      // 存储密钥
      // localStorage.setItem(
      //   "SET_KEY",
      //   Encrypt(JSON.stringify({ iv: res.data.iv, key: res.data.key }), false)
      // );
      localStorage.setItem(
        "SET_KEY",
        JSON.stringify({ iv: res.data.iv, key: res.data.key })
      );

      GlobalWs = initWebsocket((v) => {
        GlobalWs = v;
      }); //启动webSocket

      return res.data;
    });
  },
  logout ({ commit }, store) {
    console.log("store-----", store);

    commit("CLEAR_USER");
    commit("CLEAR_TOKEN");
    commit("menu/CLEAR_MENU_GROUP", null, { root: true });
    commit("menu/CLEAR_VIEW_ROUTES", null, { root: true });
    commit("process/RESET_PROCESS", null, { root: true });
    // 清除菜单
    sessionStorage.removeItem("routeIndex");
    localStorage.removeItem("SET_KEY");
    console.log("ws----------", GlobalWs);
    if (GlobalWs != null) {
      GlobalWs.close(1000);
    }
  },
  async queryUserInfo ({ commit }) {
    return getlogin().then((res) => {
      commit("SET_USERINFO", res.data.baseUserVo); //再次存储用户信息
      return res.data.baseUserVo;
    });
  },
};

export default {
  namespaced: true,
  state: state,
  actions: actions,
  mutations: mutations,
};
