const routes = [
  {
    path: '/',
    name: 'Flag',
    redirect: '/login',
    // component: () => import('@/views/login/login.vue'),
    meta: {
      title: "登录"
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/login/login.vue'),
    meta: {
      title: "登录"
    }
  },
  {
    path: '/401',
    name: '401',
    component: () => import('@/views/error/401.vue'),
    icon: '',
    meta: {
      title: '401',
    }
  },
  {
    path: '/404',
    name: '404',
    component: () => import('@/views/error/404.vue'),
    meta: {
      title: '404',
    }
  },
  {
    path: '/report',
    name: 'Report',
    component: () => import('@/views/report/index'),
    meta: {
      title: '报表'
    }
  },
  {
    path: '/secondReport',
    name: 'secondReport',
    component: () => import('@/views/secondEditReport/index'),
    meta: {
      title: '报表中心'
    }
  }
]

export default routes;