import { request } from "./request";
import qs from "qs";
const http = {
  get(url, params, custom = {}) {
    let config = {
      method: "get",
      url: url,
    };
    if (params){
      console.log("config.params",config.params)
      config.params = params;
    }
    if (custom) {
      config = { ...config, ...custom };
    }
    return request(config);
  },
  post(url, params, type) {
    const config = {
      method: "post",
      url: url,
      headers:
        type == 1
          ? { "Content-Type": "multipart/form-data" }
          : { "Content-Type": "application/x-www-form-urlencoded" },
    };
    if (params) config.data = type == 1 ? params : qs.stringify(params);
    return request(config);
  },
  postJoint(url, data, isResponseType = false) {
    let config = {
      method: "post",
      url: url,
      headers: { "Content-Type": "application/json" },
      data,
    };
    if (isResponseType) {
      config = { ...config, responseType: "blob" };
    }
    return request(config);
  },
  postCustom(args) {
    const { url, params, type, configCustom, headersCustom } = args;

    const config = {
      method: "post",
      url: url,
      headers: {
        "Content-Type":
          type == 1
            ? "multipart/form-data"
            : "application/x-www-form-urlencoded",
        ...headersCustom,
      },
      ...configCustom,
    };

    if (params) config.data = type == 1 ? params : qs.stringify(params);
    return request(config);
  },
  put(url, params) {
    const config = {
      method: "put",
      url: url,
    };
    if (params) config.params = params;
    return request(config);
  },
  delete(url, params) {
    const config = {
      method: "delete",
      url: url,
    };
    if (params) config.params = params;
    return request(config);
  },
};
export default http;
