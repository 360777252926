import CryptoJS from "crypto-js";

/*
 * 默认的 KEY IV     如果在加密解密的时候没有传入KEY和IV,就会使用这里定义的
 *
 * 前后端交互时需要前后端密钥和初始向量保持一致
 */

// const key = CryptoJS.enc.Utf8.parse("YJw52zS074a7FN7J");//  密钥        长度必须为16位
// const iv = CryptoJS.enc.Utf8.parse("6d21y6h4YqwkU1iO");
/**
 *
 * @param {Boolean} flag 判断是否为接口返回
 * @returns
 */
function getKey (flag = true) {
  let KEY, IV;
  if (flag) {
    const keyIV = localStorage.getItem("SET_KEY");
    let { key, iv } = keyIV ? JSON.parse(keyIV) : {};
    KEY = CryptoJS.enc.Utf8.parse(key); //  密钥        长度必须为16位
    IV = CryptoJS.enc.Utf8.parse(iv); //  初始向量    长度随意
  } else {
    KEY = CryptoJS.enc.Utf8.parse("1qaz2wsx3edc4rfv"); //  密钥        长度必须为16位
    IV = CryptoJS.enc.Utf8.parse("6d21y6h4Yq23safadfa"); //  初始向量    长度随意
  }
  return { KEY, IV };
}

/*
 * AES加密 ：字符串 key iv  返回base64
 */

const Encrypt = (str, flag = true) => {
  const { KEY: key, IV: iv } = getKey(flag);
  let srcs = CryptoJS.enc.Utf8.parse(str);
  var decrypt = CryptoJS.AES.encrypt(srcs, key, {
    iv: iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  });
  return CryptoJS.enc.Base64.stringify(decrypt.ciphertext);
};

/*
 * AES 解密 ：字符串 key iv  返回base64
 */
const Decrypt = (str, flag = true) => {
  const { KEY: key, IV: iv } = getKey(flag);
  var de = CryptoJS.AES.decrypt(str, key, {
    iv: iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  });
  return CryptoJS.enc.Utf8.stringify(de);
};

/**
 * MD5 加密
 */

const md5Encrypt = (signStr) => {
  return CryptoJS.MD5(signStr).toString();
};

export { Encrypt, Decrypt, md5Encrypt, getKey };
