const state = () => ({
  menuType: "", // 菜单type
});
const mutations = {
  SET_MENUTYPE(state, type) {
    state.menuType = type;
  },
};

const actions = {};

export default {
  namespaced: true,
  state: state,
  actions: actions,
  mutations: mutations,
};
