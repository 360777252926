import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
import _imports_0 from '../../assets/images/defaultIimgs/no_data.png';
import _imports_1 from '../../assets/images/defaultIimgs/indoor_not_bg.png';

const _withScopeId = n => (_pushScopeId("data-v-3121c918"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  class: "no_style"
};
const _hoisted_2 = {
  key: 0,
  src: _imports_0,
  alt: ""
};
const _hoisted_3 = {
  key: 1,
  src: _imports_1,
  alt: ""
};
import { reactive, ref, onMounted } from "vue";
export default {
  props: {
    isObj: {
      type: Object,
      default: {}
    },
    useIndoorImg: {
      type: Boolean,
      default: false
    }
  },

  setup(__props) {
    const props = __props;
    let state = reactive({});
    onMounted(() => {});
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("div", _hoisted_1, [!props.useIndoorImg ? (_openBlock(), _createElementBlock("img", _hoisted_2)) : (_openBlock(), _createElementBlock("img", _hoisted_3))]);
    };
  }

};