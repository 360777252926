const state = () => ({
  systemUnreadCount: 0, // 系统未读总数
  inboxStatus: false, // 收件箱状态更新
  systemStatus: false, // 系统消息状态更新
});

const actions = {};

const mutations = {
  SET_SYSTEMSTATUS(state) {
    state.systemStatus = !state.systemStatus;
  },
  SET_INBOXSTATUS(state) {
    state.inboxStatus = !state.inboxStatus;
  },
};

export default {
  namespaced: true,
  state: state,
  actions: actions,
  mutations: mutations,
};
