const state = () => ({
  floorId: null, //楼层id
  archId: null, //楼栋id
  inSubjectId: null, //室内的主体id
  isDeviceObj: {}, //室内设备信息
  deviceVo: {}, //某一层的告警信息
  devExceptionObj: {}, //设备异常信息
  isEquipObj: {}, //从设备管理进入室内携带的参数
  isShowAttr: false, // 是否显示温度、压力、电量等数据
  isShowExcept: false, // 是否显示异常告警数据
  websocketStatus: false, // websocket 楼栋更新状态
  // websocket 楼栋信息
  webSocketFloor: {
    // isBuildIndex,
    // floorIndex,
  },
  // 室内-楼栋用户级别：判断是否显示月度巡检
  buildUserLeve: {},
  changeBottomStatus: false, // 室内底部切换
  backred: '',
  inspectObj: {},//巡检信息
});

const mutations = {
  SET_FLOORID (state, param) {
    state.floorId = param;
  },
  SET_INSUBJECTID (state, param) {
    state.inSubjectId = param;
  },
  SET_ARCHID (state, param) {
    state.archId = param;
  },
  SET_DEVICEIDOBJ (state, param) {
    state.isDeviceObj = param;
  },
  SET_DEVICEVO (state, param) {
    state.deviceVo = param;
  },
  SET_DEVEXCEPTION (state, param) {
    state.devExceptionObj = param;
  },
  SET_ISEQUIP (state, param) {
    state.isEquipObj = param;
  },
  SET_ISSHOATTR (state, param) {
    state.isShowAttr = param;
  },
  SET_ISSHOWEXCEPT (state, param) {
    state.isShowExcept = param;
  },
  SET_WEBSOCKETSTATUS (state) {
    state.websocketStatus = !state.websocketStatus;
  },
  SETWEBSOCKETFLOOR (state, param) {
    state.webSocketFloor = param;
  },
  SETBUILDUSERLEVE (state, param) {
    state.buildUserLeve = param;
  },

  SET_CHANGEBOTTOMSTATUS (state) {
    state.changeBottomStatus = !state.changeBottomStatus;
  },
  SET_BACLRED (state, param) {
    state.backred = param
  },
  SET_INSPECTOBJ (state, param) {
    state.inspectObj = param
  }
};

const actions = {};

export default {
  namespaced: true,
  state: state,
  actions: actions,
  mutations: mutations,
};
