import { createRouter, createWebHistory } from 'vue-router';
import routes from "./modules/pages";
import views from './modules/views';
// import { viewsRoutes } from '../../mock/data/viewRoutes';//本地路由列表
import { useStore } from "@/store";

const store = useStore();
export const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: routes,
  strict: true,
})

// 挂载路由
export function loadRouter (app) {
  app.use(router);
  return router;
}

export function useRouter () {
  return router;
}

// 加载路由
export async function loadRoutes () {
  // if (!viewsRoutes) {
  //   generateDynamicRoutes(viewsRoutes)
  // } else {
  //   generateDynamicRoutes(viewsRoutes)
  // }
}
// 动态添加路由
export function generateDynamicRoutes (list) {
  console.log('接口返回的动态路由列表', list)
  let routeObj = {}
  let viewsRoutes = []
  let childrenRoute = []
  list.forEach(item => {
    childrenRoute = [];
    //组合拼接一级子菜单路由
    routeObj = {
      children: item.children.length > 0 ? childrenRoute : [],
      id: item.id,
      meta: item.meta,
      name: item.name,
      parentId: item.parentId,
      path: item.path,
      type: item.type,
      viewPath: item.viewPath,
      redirect: item.redirect,
      component: () => import(`@/${item.viewPath}`)
    }
    //拼接component进去，判断有没有子菜单,有子菜单将component添加进去
    if (item.children.length > 0) {
      let threeChildren = [];
      item.children.forEach(s => {
        if (s.children.length > 0) {
          //组合拼接三级子菜单路由
          s.children.forEach(th => {
            threeChildren.push({
              path: th.path,
              name: th.name,
              meta: {
                icon: "",
                title: th.menuName,
                show: false,
                type: 1,
                keepAlive: 1
              },
              component: () => import(`@/views/${item.componentName}/components/${th.trComponentName}.vue`)
            })
          })
        }
        //组合拼接二级子菜单路由
        childrenRoute.push({
          path: s.path,
          name: s.name,
          meta: {
            icon: s.icon ? require(`@/assets/images/alarm/${s.icon}.png`) : '',
            title: s.menuName,
            show: false,
            type: 1,
            keepAlive: 1
          },
          children: s.children.length > 0 ? threeChildren : [],
          component: () => import(`@/views/${item.componentName}/components/${s.seComponentName}.vue`)
          // component: import(`@/views/${item.componentName}/components/${s.seComponentName}.vue`)
        })
      })
    }
    console.log('添加的每个route', routeObj)
    //将组合好的路由放到数组里面
    viewsRoutes.push(routeObj)
  })
  console.log('打印看看组合后viewsRoutes', viewsRoutes)
  views.children = views.children.concat(viewsRoutes);
  console.log('所有页面views', views)
  router.addRoute(views);
  // 在动态路由添加后，在将404添加进入，解决刷新是找不到路由跳转404
  router.addRoute({
    path: '/:pathMatch(.*)',
    redirect: '/404'
  })
}