import axios from "axios";
import qs from "qs";
import { ElMessage } from "element-plus";
import { useRouter } from "vue-router";
import { getToken } from "@/utils/auth";
import { useStore } from "@/store";
import { Encrypt, Decrypt } from "@/utils/Aes.js";
let isNotAes = process.env.VUE_APP_AES == "NotAesFile"; //判断不是aes加密
console.log("不加密：", isNotAes);

const whitelist = [
  "/api-service/user/v1/xfUserLogin",
  "/api-service/user/v1/uploadCreateSubject",
  "/file-service/file/uploadImage",
  "/api-service/user/v1/applogin",
  "/api-service/user/v1/importXlsxAutoDeal",
  "/api-service/user/v1/importWHXlsxAutoDeal",
  "/api-service/user/v1/importNewDeviceDeal",
  "/open-service/open/group/v1/groupListNoToken",
  "http://10.99.85.158:9999/auth/getToken"
];

export function request (config) {
  let router = useRouter();
  // axios.defaults.withCredentials = true; //允许跨域携带cookie信息
  // 创建axios的实例
  const instance = axios.create({
    timeout: 10000,
    // transformRequest: [function (data) {
    //   return qs.stringify(data)
    // }],
  });
  // 请求拦截器配置
  instance.interceptors.request.use(
    (config) => {
      if (useStore().getters.token) {
        config.headers["x-auth-token"] = useStore().getters.token || getToken();
      }
      if (isNotAes) return config; //如果isNotAes为true不加密

      // =============== 配置加密白名单 start ================
      if (whitelist.includes(config.url)) {
        return config;
      }
      // =============== 配置加密白名单 end ==================
      if (config.data) {
        if (config.method == "post") {
          if (config.headers["Content-Type"] == "application/json") {
            //使用ase对参数加密
            config.data = {
              data: Encrypt(String(JSON.stringify(config.data)))
            };
          } else {
            config.data = qs.stringify({ data: Encrypt(String(config.data)) }); //使用ase对参数加密
          }
        }
      }
      return config;
    },
    (error) => {
      return Promise.error(error);
    }
  );
  // 响应拦截器配置
  instance.interceptors.response.use(
    (response) => {
      // console.log('数据返回--', response)
      if (isNotAes) {
        if (response.data.status == undefined) {
          return response.data;
        }
        if (response.data.status == 2 && response.data.info == "没有登录") {
          window.localStorage.removeItem("routeIndex");
          localStorage.setItem("unlogin", true);
          window.location.href = "/login";
          return;
        }
        //判断Gis地图中的接口请求时
        if (response.data?.status?.infocode == 1000) {
          return response.data;
        }
        if (response.data.status != 0) {
          // 错误数据返回
          ElMessage.warning(response.data.info);
          return;
        }
        return response.data;
      } else {
        // svg底图过滤不进行加密
        if (
          response.data &&
          typeof response.data == "string" &&
          response.data.includes("<svg")
        ) {
          return response.data;
        }
        if (response.config.method == "get") {
          let temp = null;
          try {
            let decryptData1 = JSON.parse(Decrypt(response.data));
            console.log("尝试加密", decryptData1);
            if (Object.keys(decryptData1).length) {
              temp = decryptData1;
            }
          } catch (error) {
            temp = response.data;
          }
          //判断Gis地图中的接口请求时(http://api.tianditu.gov.cn/v2/search)
          if (temp?.status?.infocode == 1000) {
            return temp;
          }
          //判断Gis地图中的接口请求时(http://api.tianditu.gov.cn/geocoder)
          if (temp?.status == "0") {
            return temp;
          }
          if (temp?.status == 2 && temp?.info == "没有登录") {
            window.localStorage.removeItem("routeIndex");
            localStorage.setItem("unlogin", true);
            window.location.href = "/login";
            return;
          }
          if (temp?.status !== 0) {
            ElMessage.warning(temp.info);
            return;
          }
          return temp;
        }
        let decryptData = null;
        // =============== 配置白名单 start ================
        // console.log("response", response);
        if (response?.config?.url && whitelist.includes(response.config.url)) {
          decryptData = response.data;
        } else {
          //先解密获取数据
          decryptData = JSON.parse(Decrypt(response.data));
        }

        // =============== 配置白名单 end ==================
        // console.log("直接解密", decryptData);
        if (decryptData.status == 2 && decryptData.info == "没有登录") {
          window.localStorage.removeItem("routeIndex");
          localStorage.setItem("unlogin", true);
          window.location.href = "/login";
          return;
        }

        if (decryptData.status !== 0 && decryptData.status) {
          ElMessage.warning(decryptData.info);
          return;
        } else {
          return decryptData;
        }
      }
    },
    (error) => {
      console.log("错误接口返回", error);
      switch (error.response.status) {
        case 400:
          router.push({ path: "/login" });
          break;
        case 403:
          console.log("token过期啦");
          router.push({ path: "/login" });
          break;
        // case 404:
        //   console.log("404啦")
        //   router.push({ path: '/404' })
        //   break
        default:
          return Promise.reject(error);
      }
      return Promise.reject(error);
    }
  );
  // 发送真正的网络请求
  return instance(config);
}
export default request;
