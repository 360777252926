
import http from '../http/http'

// 菜单列表
export function xfmenuList (data) {
  return http.post(`/api-service/user/menu/treeMenu`, data, 2)
}
//角色详情
export function roleDetail (data) {
  return http.post(`/api-service/user/role/v1/roleDetail`, data)
}

//获取内网的token来加载宝安区浅色版电子地图
export function getIntranetToken (data) { //  10.99.85.158:9999
  return http.postJoint(`http://10.99.85.158:9999/auth/getToken`, data)
}




// 菜单列表
export function menuList (data) {
  return http.post(`/api-service/user/role/v1/roleMenuList`, data)
}

export function getGaiURLToken () {
  return http.postJoint(`/api-service/user/v1/getGaiURLToken`, {})
}






