const routes = {
  path: "/",
  name: "Layout",
  redirect: "/login",
  component: () => import("@/layout/index.vue"),
  children: [
    {
      path: "/home",
      name: "home",
      component: () => import("@/views/home/index.vue"),
      meta: {
        show: false,
        icon: "",
        keepAlive: false,
        type: 1,
        title: "首页",
      },
    },
    {
      path: "/indoor",
      name: "indoor",
      component: () => import("@/views/indoor/index.vue"),
      meta: {
        show: false,
        icon: "",
        keepAlive: false,
        type: 1,
        title: "室内",
      },
    },
    {
      path: "/svg",
      name: "svg",
      component: () => import("@/views/svg/index"),
    },
    {
      path: "/indoorInspect",
      name: "indoorInspect",
      component: () => import("@/views/indoorInspect/index.vue"),
      meta: {
        show: false,
        icon: "",
        keepAlive: false,
        type: 1,
        title: "巡检室内",
      },
    },
    {
      path: "/indoorNineInspect",
      name: "indoorNineInspect",
      component: () => import("@/views/indoorNineInspect/index.vue"),
      meta: {
        show: false,
        icon: "",
        keepAlive: false,
        type: 1,
        title: "巡检室内",
      },
    },
  ],
};

export default routes;
