import http from '../http/http'
// 登录
export function getlogin (data) {
  let param = { ...data }
  // return http.post(`/api-service/user/v1/userlogin`, param)
  return http.postJoint(`/api-service/user/v1/xfUserLogin`, param)
}
//验证码
export function getCaptcha () {
  return http.get(`/api-service/user/v1/captcha`)
}

// 自动登录
export function autoLogin (data) {
  let param = { ...data }
  return http.post(`/api-service/user/v1/applogin`, param)
}