import { defineAsyncComponent } from "vue";
// 加载全局组件
export function loadComponents (app) {
  app.component("Scroll", defineAsyncComponent({
    loader: () => import("./scroll/index.vue")
  }));
  app.component("IconSvg", defineAsyncComponent({
    loader: () => import("./icon-svg/index.vue")
  }));
  app.component("FilterSwitch", defineAsyncComponent({
    loader: () => import('./filter-switch/index.vue')
  }))
  app.component("ParamSelector", defineAsyncComponent({
    loader: () => import('./param-selector/index.vue')
  }))
  app.component("EleTable", defineAsyncComponent({
    loader: () => import('./ele-table/index.vue')
  }))
  app.component("CompreTable", defineAsyncComponent({
    loader: () => import('./compre-table/index.vue')
  }))
  app.component("Dialog", defineAsyncComponent({
    loader: () => import('./dialog/index.vue')
  }))
  app.component("vscroll", defineAsyncComponent({
    loader: () => import('./vertical-scroll/index.vue')
  }))
  app.component("MoveLeft", defineAsyncComponent({
    loader: () => import('./transition-move/index.vue')
  }))
  app.component("ImgDrag", defineAsyncComponent({
    loader: () => import('./img-drag/index.vue')
  }))
  app.component("PieEchart", defineAsyncComponent({
    loader: () => import('./echart/pie.vue')
  }))
  app.component("MonthSelect", defineAsyncComponent({
    loader: () => import('./monthSelect/index.vue')
  }))
  app.component("Qrcode", defineAsyncComponent({
    loader: () => import('./qrcode/index.vue')
  }))
  app.component("RangeDate", defineAsyncComponent({
    loader: () => import('./range-date-picker/index.vue')
  }))
  app.component("Dialog2", defineAsyncComponent({
    loader: () => import('./dialog/index2.vue')
  }))
  app.component("PicUpload2", defineAsyncComponent({
    loader: () => import('./pic-upload/index2.vue')
  }))
  app.component("MonthSelectTimeUx", defineAsyncComponent({
    loader: () => import('./range-date-picker/month.vue')
  }))
  app.component("HlsPlayer", defineAsyncComponent({
    loader: () => import('./videoPlayer/hls.vue')
  }))
  app.component("Map", defineAsyncComponent({
    loader: () => import('./map/index.vue')
  }))
  app.component("CesiumPosition", defineAsyncComponent({
    loader: () => import('./mapBox/cesiumPosition.vue')
  }))
  app.component("SmallCesiumPosition", defineAsyncComponent({
    loader: () => import('./mapBox/smallCesiumPosition.vue')
  }))
  app.component("AmapInfowindow", defineAsyncComponent({
    loader: () => import('./mapBox/amapInfowindow.vue')
  }))
  app.component("CesiumMapInfowindow", defineAsyncComponent({
    loader: () => import('./mapBox/cesiumMapInfowindow.vue')
  }))
  app.component("ScoreDig",
    defineAsyncComponent({
      loader: () => import("./scoreDig/index.vue")
    })
  );
  app.component("ScorePage",
    defineAsyncComponent({
      loader: () => import("./scoreDig/scorePage.vue")
    })
  );
  app.component("baseMultipleInput",
    defineAsyncComponent({
      loader: () => import("./base-multiple-input/index.vue")
    })
  );
  app.component("BaseRate",
    defineAsyncComponent({
      loader: () => import("./baseRate/index.vue")
    })
  );
  app.component("AlarmDialog",
    defineAsyncComponent({
      loader: () => import("./alarmDialog/index.vue")
    })
  );
  app.component("NewDialog",
    defineAsyncComponent({
      loader: () => import("./dialog/newDialog.vue")
    })
  );
  app.component("MonthPicker",
    defineAsyncComponent({
      loader: () => import("./month-picker/index.vue")
    })
  );
  app.component("DisposeDialog",
    defineAsyncComponent({
      loader: () => import("./disposeDialog/index.vue")
    })
  );
}
