import { createApp } from 'vue'
import 'amfe-flexible' //引入px自动转化rem工具
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import zhCn from 'element-plus/lib/locale/lang/zh-cn'
import App from './App.vue'
import { bootstrap } from './bootstrap'

// import axios from './http/request'
import axios from 'axios'

import Empty from '@/components/empty/index.vue' //全局空状态组件

// require("../mock"); //本地模拟数据(引起GiS地圖顯示不出來  )
import "@/styles/fonts.css"; //引入液晶字体
import '@/styles/base.scss'
import '@/styles/fontcomm.css'//字体大小
import 'normalize.css'
import 'nprogress/nprogress.css'
import '@/styles/theme-color.css'//全局颜色配置
// 需要单独引入的组件样式
import "element-plus/theme-chalk/src/message.scss"
import "element-plus/theme-chalk/src/notification.scss"
import "element-plus/theme-chalk/src/message-box.scss"
//引入图标
import * as ElementPlusIconsVue from '@element-plus/icons-vue'

//按需引入vant组件
import { vant } from '@/vant-ui/vantConfig.js'

import Lottie from "vue-lottie"

import install from './utils/preventReClick'

axios.defaults.baseURL = process.env.VUE_APP_ADDZHXF == 'ZHXF' ? '/zhxf/' : ' ' //所有内网接口增加/zhxf/

const app = createApp(App)
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}
app.directive('dragDrop', (el) => {
  el.onmousedown = (e) => {
    e.stopPropagation();
    let disx = e.pageX - el.offsetLeft;
    let disy = e.pageY - el.offsetTop;
    document.onmousemove = (e) => {
      e.preventDefault();
      // console.log('拖拽了', e.pageX - disx)
      let xVal = e.pageX - disx
      let yVal = e.pageY - disy
      el.style.left = xVal + 'px';
      el.style.top = yVal + 'px';
    }
    document.onmouseup = () => {
      document.onmousemove = document.onmouseup = null;
    }
  }
})
//自定义拖动Dialog指令
app.directive('dialogDrag', (el) => {
  el.onmousedown = (ev) => {
    el.style.cursor = 'move'
    // 鼠标按下的位置
    const mouseXStart = ev.clientX;
    const mouseYStart = ev.clientY;

    // 当前滑块位置
    const rectLeft = el.offsetLeft;
    const rectTop = el.offsetTop;
    document.onmousemove = (e) => {
      // 鼠标移动的位置
      const mouseXEnd = e.clientX;
      const mouseYEnd = e.clientY;
      const moveX = mouseXEnd - mouseXStart + rectLeft;
      const moveY = mouseYEnd - mouseYStart + rectTop;
      el.style["top"] = moveY + "px";
      el.style["left"] = moveX + "px"

    }
    document.onmouseup = () => {
      // 取消事件
      el.style.cursor = 'default'
      document.onmousemove = null;
    }
  }
})
app.use(ElementPlus, { locale: zhCn })
app.component('Empty', Empty) //挂载组件
app.component("Lottie", Lottie)
app.use(install)
vant(app)

bootstrap({ app, }).then(async ({ router }) => {
  router.isReady().then(() => app.mount('#app'))
})
app.config.globalProperties.$axios = axios;


