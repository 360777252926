import { createI18n } from "vue-i18n/index";
import storage from "@/utils/storage";

import enLocale from "element-plus/lib/locale/lang/en";
import zhLocale from "element-plus/lib/locale/lang/zh-cn";

import en from "./locales/en";
import zh from "./locales/zh-cn";

// const messages = {
//     [enLocale.name]:{
//         el: enLocale.el,
//         ...en
//     },
//     [zhLocale.name]:{
//         el: zhLocale.el,
//         ...zh
//     }
// }

const messages = {
  [enLocale.name]: {
    el: { ...enLocale.el, ...en },
    name: enLocale.name,
  },
  [zhLocale.name]: {
    el: { ...zhLocale.el, ...zh },
    name: zhLocale.name,
  },
};

function getLanguage() {
  return storage.get("language") || "zh-cn";
}

export const getLocale = () => {
  const cookieLanguage = getLanguage();
  if (cookieLanguage) {
    return messages[cookieLanguage];
  }

  const language = navigator.language.toLowerCase();
  const locales = Object.keys(messages);
  for (const locale of locales) {
    if (language.indexOf(locale) > -1) {
      return messages[locale];
    }
  }

  // Default language is zh-cn
  return zhLocale.name;
};

const i18n = createI18n({
  locale: getLanguage(),
  messages: messages,
});

export function loadI18n(app) {
  app.use(i18n);
}

export function useI18n() {
  return i18n;
}
