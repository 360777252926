import NProgress from "nprogress";
import { useStore } from "@/store";
import { ElNotification } from "element-plus";
import { xfmenuList } from "@/api/user";
import { awaitWraper } from "@/utils/awaitWraper";
import { generateDynamicRoutes } from "@/router";
import storage from "@/utils/storage";
NProgress.configure({ showSpinner: false });

const loginIgnore = {
  names: ["404", "401"], //根据路由名称匹配
  paths: ["/login", "/secondReport", "/report"], //根据路由fullPath匹配
  // 判断路由是否包含在该配置中
  includes (route) {
    return this.names.includes(route.name) || this.paths.includes(route.path);
  },
};

// 加载路由守卫
const store = useStore();
export function loadGuards (router) {
  router.beforeEach(async (to, from, next) => {
    // 注意点  新建一个菜单时，进入该菜单会这个to会是404；
    //处理方式：需要在本地的viewRoutes.js中添加对应的配置,并退出登入
    console.log("路由to", to);
    console.log("路由from", from);
    if (
      from.path != "/indoor" &&
      from.path != "/poll" &&
      from.path != "/home" &&
      to.path == "/indoorInspect"
    ) {
      sessionStorage.setItem("isHasGoBack", false);
    } else {
      sessionStorage.setItem("isHasGoBack", true);
    }

    if (!NProgress.isStarted()) {
      NProgress.start();
    }
    if (store.getters.token && !loginIgnore.includes(to)) {
      //检验token 
      !store.getters.permisRoutesList?.length && (await getMenuList());
      let permisRoutes = store.getters.permisRoutesList;
      console.log("再次获取权限路由列表```````````", permisRoutes);
      if (to.name == "login") {
        next({ path: "/login" });
        NProgress.done();
      } else if (to.name == "indoor") {
        next();
        NProgress.done();
      } else if (to.name == "indoorInspect") {
        next();
        NProgress.done();
      } else if (to.name == "indoorNineInspect") {
        next();
        NProgress.done();
      }
      // else if (to.name == '404') {
      //   next()
      //   NProgress.done();
      // }
      else {
        const hasRoute = router.hasRoute(to.name);
        console.log("是否有路由存在", hasRoute);
        if (hasRoute) {
          if (permisRoutes.length > 0) {
            let found = false;
            for (let i = 0; i < permisRoutes.length; i++) {
              // console.log("permisRoutes[i]", permisRoutes[i]);
              if (to.path == permisRoutes[i]) {
                found = true;
                break;
              }
            }
            if (found == false) {
              ElNotification({
                title: "温馨提醒",
                message: "您无权限，请联系您的管理员！",
                type: "warning",
              });
              NProgress.done();
              return false;
            }
          }
          if (!store.getters.userInfo) {
            await store.dispatch("user/queryUserInfo");
          }
          await store.commit("process/ADD_PROCESS", {
            keepAlive: to.meta.keepAlive,
            label: to.meta.title,
            value: to.fullPath,
            name: to.name,
          });
          next();
        } else {
          next({ ...to, replace: true });
        }
      }
    } else {
      if (!loginIgnore.includes(to)) {
        next("/login");
        return;
      }
      if (to.path == "/secondReport" || to.path == "/report") {
        let sId = to.query.sId;
        let paramsT = to.query.t;
        if (sId > 0 && paramsT !== undefined && paramsT !== null) {
          next();
          NProgress.done();
          return;
        }
        next({ path: "/login", replace: true });
        NProgress.done();
      } else {
        next();
      }
    }
  });

  router.afterEach(() => {
    NProgress.done();
  });
}
const getMenuList = async () => {
  let dynamicRouter = [];//动态路由列表
  let menuRouter = {};//菜单
  let children = [];//子菜单
  const res = await awaitWraper(xfmenuList());
  if (res[1] !== undefined) {

    console.log("动态返回的菜单列表", res[1].data);
    let datas = res[1].data;
    datas.forEach((el, index) => {
      children = [];
      menuRouter = {
        id: index,
        name: el.path,
        parentId: index,
        path: el.path,
        type: el.type,
        componentName: el.component,
        viewPath: `views/${el.component}/index.vue`,
        children: el.childrenMenuVO.length > 0 ? children : [],
        meta: {
          icon: el.icon,
          keepAlive: el.keepAlive,
          show: el.isVisible == 0 ? true : false,
          title: el.menuName,
        },
      };
      //一级菜单重定向(告警管理、设备管理、综合管理)
      if (el.path === '/alarm' || el.path === '/equipment' || el.path === '/manage') {
        if (el.childrenMenuVO.length != 0) {
          menuRouter.redirect = el.childrenMenuVO[0].path;
        }
      }
      if (el.childrenMenuVO.length > 0) {
        let threeChildren = [];
        el.childrenMenuVO.forEach((val) => {
          threeChildren = [];
          if (val.childrenMenuVO.length > 0) {
            val.childrenMenuVO.forEach((ch) => {
              // console.log('看看三级ch', ch)
              //组合拼接三级子菜单
              if (el.path == '/poll' && val.path == '/poll/wyPoll') {
                // console.log('数组中的第一个', val.childrenMenuVO[0].path)
                menuRouter.redirect = val.childrenMenuVO[0].path;
              }
              //前置条件是综合管理的总览不存在时
              if (val.path.includes('/manage/overView')) {
                if (el.path == '/manage' && val.path == '/manage/unitManage') {
                  menuRouter.redirect = val.childrenMenuVO[0].path;
                }
              }
              threeChildren.push({
                path: ch.path,
                name: ch.component,
                trComponentName: ch.component,
                meta: {
                  icon: "",
                  title: ch.menuName,
                  show: ch.isVisible == 1 ? true : false,//show 用来控制侧边栏菜单的显示与隐藏；show为true则隐藏，反之显示
                  type: 1,
                  keepAlive: 1,
                },
              });
            });
          }
          //组合拼接二级子菜单
          children.push({
            path: val.path,
            name: val.component,
            seComponentName: val.component,
            meta: {
              icon: val.icon ? require(`@/assets/images/alarm/${val.icon}.png`) : '',
              title: val.menuName,
              show: false,
              type: 1,
              keepAlive: 1,
            },
            children: val.childrenMenuVO.length > 0 ? threeChildren : [],
          });
        });
      }
      dynamicRouter.push(menuRouter);
    });
  }

  console.log("组合后的router-----------", dynamicRouter);
  let subjectVo = storage.get("subjectVo");
  if (subjectVo.subjectType == 3 && subjectVo.subjectSubType == 4) {
    //登录账号是//街道账号
    let newClound = {
      children: [],
      componentName: 'safetyClound',
      id: 6,
      meta: { icon: '', keepAlive: 0, show: true, title: '消安检查' },
      name: 'safetyClound',
      parentId: 6,
      path: '/safetyClound',
      type: 2,
      viewPath: 'views/safetyClound/index.vue'
    }
    dynamicRouter.push(newClound)
  }
  await generateDynamicRoutes(dynamicRouter);
  store.commit("menu/SET_TOPMENULIST", dynamicRouter); //存储后端返回的路由列表
  let arr = [];
  loop(dynamicRouter, arr);
  store.commit("menu/SET_PERMI_ROUTERS", arr); //将后端返回的路由列表权限存在vuex
};
const loop = (item, arr) => {
  //递归遍历
  item.forEach((v) => {
    arr.push(v.path)//存权限列表
    if (v.children && v.children.length > 0) {
      loop(v.children, arr)
    }
  })
};
