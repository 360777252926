const colors = {
  primary: "#1890ff",
  success: "#13ce66",
  warning: "#ffba00",
  danger: "#ff4949",
  info: "#5d5d5d",
};

export const variables = {
  theme: colors.danger,
};

export const fontSize = {
  fs12: 12,
  fs13: 13,
  fs14: 14,
  fs15: 15,
  fs16: 16,
  fs18: 18,
};
