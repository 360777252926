/**
 * 
 * @param {*} wsurl 长链接
 * 
 * @returns 
 */
import { getToken } from '@/utils/auth'
//引入订阅的库
import pubsub from "pubsub-js"

export function initWebsocket (fn) {

  let websock
  let timer
  let timerReconnect
  let lastAlarmTime = 0
  let wsurl = ''
  let isProduction = process.env.NODE_ENV == 'production' //判断当前的环境
  if (isProduction) { // 生产环境
    wsurl = 'wss://cloud.websocket.tdconnect.cn'//生产环境
    // wsurl = 'ws://39.108.169.54:18000'//生产环境
    // wsurl = 'ws://10.97.53.13:20006'//政务内网环境
  } else { //开发环境
    wsurl = 'ws://10.97.53.13:20006'//政务内网环境
    // wsurl = 'wss://cloud.websocket.test.tdconnect.cn' //测试测试
    // wsurl = 'ws://192.168.31.11:18000' //小朱本地
  }
  //初始化websocket
  let token = getToken()
  if (token == '' || token == undefined) {
    return
  }
  let url = `${wsurl}?${token}`
  websock = new WebSocket(url)
  websock.onopen = function () {
    /** 连接成功 **/
    console.log('----------------通讯开始-----------')
    // 发送心跳防止ws协议自动断联
    timer = setInterval(() => {
      if (websock.readyState == WebSocket.OPEN) {
        websock.send(2);
      }
    }, 1000 * 10)
  }
  websock.onerror = function () {
    /* * 通讯异常 * */
    console.log('通讯异常')
    // timerReconnect = setInterval(() => {
    //   console.log("websock.readyState:", websock.readyState)
    //   if (websock.readyState == WebSocket.CLOSED) {
    //     clearInterval(timerReconnect)
    //     websock = initWebsocket()
    //     if (fn != null) {
    //       fn(websock)
    //     }
    //   }
    // }, 1000 * 10)
    // setTimeout(() => {
    //   websock = initWebsocket()
    //   if (fn != null) {
    //     fn(websock)
    //   }
    // }, 1000 * 10)
  }
  websock.onclose = function (ev) {
    /* * 关闭连接时回调函数 * */
    console.log('连接断开---------')
    clearInterval(timer)//清除心跳定时器
    if (ev != null && ev.code == 1000) return
    timerReconnect = setInterval(() => {
      if (websock.readyState == WebSocket.CLOSED) {
        clearInterval(timerReconnect)
        websock = initWebsocket(fn)
        if (fn != null) {
          fn(websock)
        }
      }
    }, 1000 * 60)
  }
  websock.onmessage = function (e) {
    /* * 接收服务端消息 * */
    let data = JSON.parse(e.data)
    if (data == null) return
    if (data.msgType >= 1 && data.msgType <= 4) {
      let now = new Date().getTime()
      if (now - lastAlarmTime < 5) return
      lastAlarmTime = now
    }
    // console.log('json格式数据-----', data)
    pubsub.publish('socketMsg', data)//发布消息
    // cb(data)
  }
  return websock
}