import { toRaw } from "vue";
export default {
  // 用户token
  token: (state) => state.user.token,
  // 语言
  language: (state) => state.app.language,
  // 用户信息
  userInfo: (state) => state.user.userInfo,
  // 菜单列表
  menuGroup: (state) => toRaw(state.menu.menuGroup),
  // 路由列表
  viewRoutes: (state) => toRaw(state.menu.viewRoutes),
  // 菜单是否展开
  menuCollapse: (state) => state.menu.collapse,
  // 窗口列表
  processList: (state) => state.process.list,
  //权限路由列表
  permisRoutesList: (state) => state.menu.permisRoutesList,
  //顶部路由菜单
  topMenuList: (state) => state.menu.topMenuList,
  hasFlat: (state) => state.user.hasFlat,

  /** 首页大屏 *************/
  //主体信息
  headerInfo: (state) => state.user.headerInfo,
  //主体id
  subjectId: (state) => state.home.subjectId,
  //某个街道的subjectid
  streetSubjectId: (state) => state.home.streetSubjectId,
  //区域id
  regionId: (state) => state.home.regionId,
  //1级名称
  levelOneName: (state) => state.home.levelOneName,
  //2级名称
  levelTwoName: (state) => state.home.levelTwoName,
  //3级名称
  levelThreeName: (state) => state.home.levelThreeName,
  //地图实例
  map: (state) => state.home.map,
  //室外标识
  isShowOutdoor: (state) => state.home.isShowOutdoor,
  //拖拽x的值
  dragXValue: (state) => state.home.dragXValue,
  //拖拽y的值
  dragYValue: (state) => state.home.dragYValue,
  //层级关系值
  rierObj: (state) => state.home.rierObj,
  //编辑回显内容
  editorEchObj: (state) => state.home.editorEchObj,
  //路径集合
  pathArr: (state) => state.home.pathArr,
  //三小信息
  threeSmallInfo: (state) => state.home.threeSmallInfo,
  //某社区的主体id
  communitySubjectId: (state) => state.home.communitySubjectId,
  //是否点击了社区
  isClickcommunity: (state) => state.home.isClickcommunity,
  //是否点击了室内
  isClickIndoor: (state) => state.home.isClickIndoor,
  //是否点击了室外
  isClickOutdoor: (state) => state.home.isClickOutdoor,
  //每一个对应街道的信息
  streetInfo: (state) => state.home.streetInfo,
  //室外的subjectId
  outdoorSubjectId: (state) => state.home.outdoorSubjectId,
  //保存当前从社区选中的层级
  currentSelectLevel: (state) => state.home.currentSelectLevel,
  //保存cesium地图某块regionId
  cesiumMapRegionId: (state) => state.home.cesiumMapRegionId,
  //保存之前的subjectId
  subjectIdList: (state) => state.home.subjectIdList,
  //摄像机高度
  cameraHeight: (state) => state.home.cameraHeight,
  //设备管理进入室外的标识
  devicefalt: (state) => state.home.devicefalt,

  /** 室内 *******************/
  //室内的楼层id
  floorId: (state) => state.indoor.floorId,
  //室内的楼栋id
  archId: (state) => state.indoor.archId,
  //室内的主体id
  inSubjectId: (state) => state.indoor.inSubjectId,
  //室内设备信息
  isDeviceObj: (state) => state.indoor.isDeviceObj,
  //某一层的告警信息
  deviceVo: (state) => state.indoor.deviceVo,
  //设备异常信息
  devExceptionObj: (state) => state.indoor.devExceptionObj,
  //从设备管理进入室内携带的参数
  isEquipObj: (state) => state.indoor.isEquipObj,
  // 是否显示温度、压力、电量等数据
  isShowAttr: (state) => state.indoor.isShowAttr,
  // 是否显示异常告警数据
  isShowExcept: (state) => state.indoor.isShowExcept,
  // websocket 楼栋更新状态
  websocketStatus: (state) => state.indoor.websocketStatus,
  // websocket 楼栋信息
  webSocketFloor: (state) => state.indoor.webSocketFloor,
  // 室内-楼栋用户级别：判断是否显示月度巡检
  buildUserLeve: (state) => state.indoor.buildUserLeve,
  // 室内底部tab切换
  changeBottomStatus: (state) => state.indoor.changeBottomStatus,
  backred: (state) => state.indoor.backred,
  //室内巡检信息
  inspectObj: (state) => state.indoor.inspectObj,

  /** 告警 *******************/
  //告警模块记录类型
  alarmType: (state) => state.alarm.alarmType,

  /** baseMenu */
  // 菜单type
  menuType: (state) => state.basemenu.menuType,

  /** 自检自查 *******************/
  baseSubjectVo: (state) => state.home.baseSubjectVo,
};
